import store from '@/store/store';
import { Module } from 'vuex';
import { IContactClient } from '@/models/ContactClient';
import { IContactAddress } from '@/models/ContactAddress';
import { debug } from '@/services/Debugger';
import { AxiosResponse } from 'axios';

const contact: Module<any, any> = {
    state: {
        contact_id: '',
        contact_info: {}
    },
    getters: {
        contact_id: state => {
            return state.contact_id;
        },
        contact_info: state => {
            return state.contact_info;
        },
        contact_info_fullname: state => {
            return state.contact_info.v_display_name;
        },
        contact_info_shortname: state => {
            return state.contact_info.shortname;
        }
    },
    mutations: {
        SET_CONTACT_ID: (state, payload) => {
            state.contact_id = payload.contact_id;
        },
        SET_CONTACT_INFO: (state, payload) => {
            state.contact_info = payload.contact_info;
        }
    },
    actions: {
        GET_CONTACT_INFO({ commit }) {
            debug.debug('act:GET_CONTACT_INFO');
            return this.getters.apiService.getContact(this.getters.contact_id).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    let resultData: IContactClient = result.data.data[0];
                    commit('SET_CONTACT_INFO', { contact_info: resultData });
                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                    return false;
                }
            });
        }
    }
};
export default contact;
