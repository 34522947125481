













import Vue from 'vue';
import { mapGetters } from 'vuex';

import AfOcHeader from '@/components/AfOcHeader.vue';

export default Vue.extend({
    name: 'af-isloading',
    components: {
        AfOcHeader
    },
    computed: {
        ...mapGetters(['isError', 'apiErrormessage', 'mailtoSubject', 'mailtoBody'])
    }
});
