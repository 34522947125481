<template>
    <div id="autoflex-oc-app">
        <router-view :settings="settings"></router-view>
        <div class="version">Powered by Autoflex | {{ this.$store.getters.appVersion }}</div>
    </div>
</template>

<script>
export default {
    props: ['settings']
};
</script>

<style scoped>
.version {
    text-align: right;
    font-size: 8px;
    margin-right: 10px;
}
</style>
