import Vue from 'vue';
import VueRouter from 'vue-router';
import Portal from '@/views/Portal.vue';
import Pdf from '@/views/Pdf.vue';

let initialized = false;

export default function() {
    if (initialized) {
        return;
    }

    Vue.use(VueRouter);

    const routes = [
        {
            path: '/pdf',
            name: 'pdf',
            component: Pdf
        },
        {
            path: '/',
            name: 'home',
            component: Portal
        },
        {
            path: '*',
            component: Portal
        }
    ];

    const router = new VueRouter({ routes, mode: 'history' });

    Vue.mixin({ router });
    initialized = true;
}
