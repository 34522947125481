import store from '@/store/store';
import { Module } from 'vuex';
import { IOwner } from '@/models/Owner';
import { debug } from '@/services/Debugger';
import { AxiosResponse } from 'axios';

const owner: Module<any, any> = {
    state: {
        owner_id: '',
        owner_info: {},
        organization_logo: '<img src="/img/logo.svg"/>'
    },
    getters: {
        owner_id: state => {
            return state.owner_id;
        },
        owner_info: state => {
            return state.owner_info;
        },
        owner_info_name: state => {
            return state.owner_info.name;
        },
        organization_logo: state => {
            return state.organization_logo;
        }
    },
    mutations: {
        SET_OWNER_ID: (state, payload) => {
            state.owner_id = payload.owner_id;
        },
        SET_OWNER_INFO: (state, payload) => {
            state.owner_info = payload.owner_info;
        },
        SET_ORGANIZATION_LOGO: (state, payload) => {
            state.organization_logo = payload.logo;
        }
    },
    actions: {
        GET_OWNER_INFO({ commit }) {
            debug.debug('act:GET_OWNER_INFO');
            return this.getters.apiService.getOwner(this.getters.owner_id).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    let resultData: IOwner = result.data.data[0];
                    commit('SET_OWNER_INFO', { owner_info: resultData });
                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                    return false;
                }
            });
        },
        GET_ORGANIZATION_LOGO({ commit }) {
            debug.debug('act:GET_ORGANIZATION_LOGO');
            return this.getters.apiService.getOrganizationLogo(this.getters.owner_id).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    let resultData = URL.createObjectURL(result.data);
                    if (resultData) {
                        commit('SET_ORGANIZATION_LOGO', { logo: resultData });
                    } else {
                        commit('SET_ORGANIZATION_LOGO', {
                            logo: '<img src="/img/logo.svg"/>'
                        });
                    }

                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                    return false;
                }
            });
        }
    }
};
export default owner;
