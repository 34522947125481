/**
 * @author Martijn de Bondt
 * @since 18-02-2012
 *
 * Debug override for coloring and extending window.console
 * TODO debug line no. not the original place, but the line in this Class
 */
import store from '@/store/store';

class DebugService {
    constructor() {}
    // console.log() -> header
    // c = console.clear()
    // l = console.log()
    // i = console.info()
    // d = console.debug()
    // e = console.error()
    // t = console.table()
    // r = console.response() -> api response just for us
    // s = console.success() -> success response just for us
    // k = console.kalendar() -> kalendar response just for us
    // w = console.warn()
    // x = console.trace()
    // z = console.dir()

    // method string = "clidetrwxz"

    /**
     * Clear console log, alters line numbers
     * @example debug.clear()
     * @extends window.console.clear()
     */
    public clear() {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('c');
        if (isDebug && isMethod) {
            console.clear();
        }
    }

    /**
     * Console header, alters line numbers
     * @example debug.header('string', Object)
     * @extends window.console.log()
     */
    public header(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        if (isDebug) {
            additionalParams.length === 0
                ? console.log(`%c${data}`, 'color: #ff0000; font-size: 14px; font-weight: bold;')
                : console.log(`%c${data}`, 'color: #ff0000; font-size: 14px; font-weight: bold;', additionalParams);
        }
    }

    /**
     * Console log, alters line numbers
     * @example debug.log('string', Object)
     * @extends window.console.log()
     */
    public log(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('l');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.log(`%c${data}`, 'color: #999;') : console.log(`%c${data}`, 'color: #999;', additionalParams);
        }
    }

    /**
     * Console info, alters line numbers
     * @example debug.info('string', Object)
     * @extends window.console.info()
     */
    public info(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('i');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.info(`%c${data}`, 'color: #5ADDFA;') : console.info(`%c${data}`, 'color: #5ADDFA;', additionalParams);
        }
    }

    /**
     * Console warn, alters line numbers
     * @example debug.warn('string', Object)
     * @extends window.console.warn()
     */
    public warn(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('w');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.warn(`%c${data}`, 'color: #FD9720;') : console.warn(`%c${data}`, 'color: #FD9720;', additionalParams);
        }
    }

    /**
     * Console debug, alters line numbers
     * @example debug.debug('string', Object)
     * @extends window.console.log()
     */
    public debug(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('d');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.log(`%c${data}`, 'color: #333333;') : console.log(`%c${data}`, 'color: #333333;', additionalParams);
        }
    }

    /**
     * Console error, alters line numbers
     * @example debug.error('string', Object)
     * @extends window.console.error()
     */
    public error(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('e');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.error(`%c${data}`, 'color: #E70707;') : console.error(`%c${data}`, 'color: #E70707;', additionalParams);
        }
    }

    /**
     * Console success, alters line numbers
     * @summary specially for distinction API responses
     * @example debug.success('string', Object)
     * @extends window.console.log()
     */
    public success(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('s');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.log(`%c${data}`, 'color: #0FF113;') : console.log(`%c${data}`, 'color: #0FF113;', additionalParams);
        }
    }

    /**
     * Console response, alters line numbers
     * @summary specially for distinction API responses
     * @example debug.response('string', Object)
     * @extends window.console.log()
     */
    public response(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('r');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.log(`%c${data}`, 'color: #2eb331;') : console.log(`%c${data}`, 'color: #2eb331;', additionalParams);
        }
    }

    /**
     * Console response, alters line numbers
     * @summary specially for distinction Kalendar responses
     * @example debug.response('string', Object)
     * @extends window.console.log()
     */
    public kalendar(data: any, ...additionalParams: any[]) {
        let isDebug = store.state.debugMode;
        let isMethod = store.state.debugMethod.includes('k');
        if (isDebug && isMethod) {
            additionalParams.length === 0 ? console.log(`%c${data}`, 'color: #eb34c3;') : console.log(`%c${data}`, 'color: #eb34c3;', additionalParams);
        }
    }
}
export const debug = new DebugService();
