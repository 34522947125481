











































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { debug } from '@/services/Debugger';
import { DateTime } from 'luxon';
export default Vue.extend({
    name: 'af-menu',
    components: {},
    data: function() {
        return {
            count: 0
        }
    },
    computed: {
        ...mapGetters(['contact_info_fullname','contact_info_shortname', 'organization_logo', 'afSettings','customer'])
    },
    mounted: function() {},
    methods: {}
    });
