import Vue from 'vue';
import Vuex from 'vuex';

import globals from './modules/globals';
import contact from './modules/contact';
import owner from './modules/owner';
import document from './modules/document';

import { debug } from '@/services/Debugger';
import { AxiosResponse } from 'axios';
import ApiService from '@/services/ApiService';
import { IAuthenticationComponent } from '@/models/AuthenticationComponent';

Vue.use(Vuex);

const debugStore = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        globals: globals,
        contact: contact,
        owner: owner,
        document: document
    },
    strict: debugStore,
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0',
        component_token: '', // embed_token -> request
        token: '',
        token_valid_until: '',
        environment: '',
        username: '',
        password: '',
        debugMode: 'false',
        debugText: 'Console Logs actief',
        debugMethod: 'clidetrswxz',
        APILocation: 'dev',
        isError: false,
        isFetching: true,
        loadingMessage: 'Data ophalen',
        apiErrormessage: '',
        mailtoSubject: 'AF - Online Klantportaal: Fout met ophalen data',
        mailtoBody: 'AF - Online Klantportaal: Fout met ophalen data. Server: ' + window.location,
        ApiService: Object
    },
    getters: {
        apiService: state => {
            return state.ApiService;
        },
        appVersion: state => {
            return state.packageVersion;
        },
        component_token: state => {
            return state.component_token;
        },
        appToken: state => {
            return state.token;
        },
        token_valid_until: state => {
            return state.token_valid_until;
        },
        environment: state => {
            return state.environment;
        },
        username: state => {
            return state.username;
        },
        password: state => {
            return state.password;
        },
        debugMode: state => {
            return state.debugMode;
        },
        debugText: state => {
            return state.debugText;
        },
        debugMethod: state => {
            return state.debugMethod;
        },
        APILocation: state => {
            return state.APILocation;
        },
        isError: state => {
            return state.isError;
        },
        isFetching: state => {
            return state.isFetching;
        },
        loadingMessage: state => {
            return state.loadingMessage;
        },
        apiErrormessage: state => {
            return state.apiErrormessage;
        },
        mailtoSubject: state => {
            return state.mailtoSubject;
        },
        mailtoBody: state => {
            return state.mailtoBody;
        }
    },
    mutations: {
        SET_APISERVICE: (state, payload) => {
            state.ApiService = payload.new;
        },
        SET_COMPONENTTOKEN: (state, payload) => {
            state.component_token = payload.token;
        },
        SET_TOKEN: (state, payload) => {
            state.token = payload.token;
        },
        SET_TOKENVALIDUNTILL: (state, payload) => {
            state.token_valid_until = payload.valid;
        },
        SET_ENVIRONMENT: (state, payload) => {
            state.environment = payload.environment;
        },
        SET_USERNAME: (state, payload) => {
            state.username = payload.username;
        },
        SET_PASSWORD: (state, payload) => {
            state.password = payload.password;
        },
        SET_DEBUGMODE: (state, payload) => {
            state.debugMode = payload.mode;
            state.debugMethod = payload.method;
            state.debugText = payload.text;
        },
        SET_APILOCATION: (state, payload) => {
            state.APILocation = payload.location;
        },
        SET_ISERROR: (state, payload) => {
            state.isError = payload.state;
        },
        SET_ISFETCHING: (state, payload) => {
            //console.log('mut:SET_ISFETCHING', payload.state);
            state.isFetching = payload.state;
        },
        SET_LOADINGMESSAGE: (state, payload) => {
            //console.log('mut:SET_LOADINGMESSAGE', payload.message);
            state.loadingMessage += payload.message;
        },
        SET_API_ERRORMESSAGE: (state, payload) => {
            state.apiErrormessage = payload.message;
        }
    },
    actions: {
        SET_APISERVICE() {},
        async GET_TOKEN({ commit, dispatch }) {
            debug.log('act:GET_TOKEN');
            // set store ApiService -> new
            const apiService = new ApiService();
            commit('SET_APISERVICE', { new: apiService });
            // use prev. set store apiService
            if (this.getters.apiService.isServerAccessible()) {
                await dispatch('GET_DOCUMENT_INFO');
                return true;
                // return apiService.getAuthenticateComponentToken().then(async (result: AxiosResponse) => {
                //     if (result.status === 200) {
                //         let resultData: IAuthenticationComponent = result.data;
                //         commit('SET_TOKEN', { token: resultData.token });
                //         commit('SET_TOKENVALIDUNTILL', { valid: resultData.token_valid_until });
                //         commit('SET_ENVIRONMENT', { environment: resultData.environment });
                //         // await dispatch('GET_AF_SETTINGS');
                //         // await dispatch('GET_ORGANIZATION_LOGO');
                //         // await dispatch('GET_DOCUMENT_INFO');
                //         // await dispatch('GET_CONTACT_INFO');
                //         return true;
                //     } else {
                //         commit('SET_ISERROR', { state: true });
                //         commit('SET_ISFETCHING', { state: false });
                //         commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                //         return false;
                //     }
                // });
            } else {
                commit('SET_ISERROR', { state: true });
                commit('SET_ISFETCHING', { state: false });
                commit('SET_API_ERRORMESSAGE', 'Er kon geen verbinding worden gemaakt met de server probeer het later nog eens');
            }
        }
    }
});
