




















import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debug } from '@/services/Debugger';
import { ISettings } from '@/models/Settings';

// components
import AfDebugMode from '@/components/AfDebugMode.vue';
import AfIsLoading from '@/components/AfIsLoading.vue';
import AfIsError from '@/components/AfIsError.vue';
import AfMenu from '@/components/AfMenu.vue';
import AfDocumentInfo from '@/components/AfDocumentInfo.vue';
//@ts-ignore
import pdfvuer from 'pdfvuer'

// fontawesome i fix
// import { dom } from '@fortawesome/fontawesome-svg-core';
// dom.watch();

export default Vue.extend({
    name: 'pdf',
    components: {
        AfIsLoading,
        AfIsError,
        AfDebugMode,
        AfMenu,
        AfDocumentInfo,
        pdfvuer
    },
    data() {
        return {
            loadingMessage: '',
            // do the settings
            settingsAttr: {} as ISettings,
            settings: {} as ISettings,
            // mail settings to helpdesk onError
            mailtoSubject: 'AF - Klantportaal: Fout met ophalen data',
            mailtoBody: 'AF - Klantportaal: Fout met ophalen data. Server: ' + window.location
        };
    },
    created: async function() {
        debug.log('created', this.$data);
        debug.clear();

        // set default settings JSON
        this.$data.settings = {
            token: '',
            debugMode: { active: false, text: 'LET OP! DEVELOPER MODE', method: 'clidetrskwxz' },
            APILocation: 'nl'
        };
        // get JSON from attributes
        this.$data.settingsAttr = this.$attrs.settings ? (JSON.parse(this.$attrs.settings) as ISettings) : ({} as ISettings);
        // merge default set with override from attributes
        this.$data.settings = Object.assign(this.$data.settings, this.$data.settingsAttr);

        //!@# TODO set store values combined as SET_SETTINGS
        this.$store.commit('SET_COMPONENTTOKEN', { token: this.$data.settings.token });
        this.$store.commit('SET_DEBUGMODE', { mode: this.$data.settings.debugMode.active, method: this.$data.settings.debugMode.method });
        this.$store.commit('SET_APILOCATION', { location: this.$data.settings.APILocation });

        debug.header('---- AUTOFLEX - ONLINE CUSTOMER - DEBUGMODE ----');
        // always show version, hence use console.log
        console.log('Autoflex Online Customer version: ' + this.appVersion);
        debug.log('settings: ', this.$data.settings);
    },
    mounted: function() {
        debug.log('mounted');
        this.createPortal();
        // if (this.$data.settings.token || (this.$data.settings.username && this.$data.settings.password)) {
        //     debug.warn('check data', this.$data.settings.token, this.$data.settings.username, this.$data.settings.password);
        //     //@ts-ignore
        //     this.createPortal();
        // } else {
        //     this.$store.commit('SET_ISERROR', { state: true });
        //     this.$store.commit('SET_ISFETCHING', { state: false });
        //     if (!this.$data.token) {
        //         this.$store.commit('SET_API_ERRORMESSAGE', { message: 'Token missing check settings' });
        //     } else {
        //         this.$store.commit('SET_API_ERRORMESSAGE', { message: 'Username and Password missing check settings' });
        //     }
        //     return;
        // }
    },
    computed: {
        ...mapGetters(['isFetching', 'isError', 'apiErrormessage', 'isDocumentRequest','appVersion', 'document_info_pdf', 'document_info_url'])
    },
    methods: {
        ...mapMutations(['SET_ISFETCHING', 'SET_LOADINGMESSAGE', 'SET_PUBLIC_KEY', 'SET_PASSCODE','SET_CUSTOMER']),
        ...mapActions(['GET_TOKEN']),
        /**
         * createPortal()
         * actually create the Customer Portal code / page
         */
        createPortal: function() { 
            // first check if we have a pdf/document request and if we are customer or not
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const public_key = params.get('k');
            const passcode = params.get('p');
            const customerN = params.get('c');
            if(public_key && passcode){
                debug.warn('detail params', public_key, passcode);
                this.$store.commit('SET_PUBLIC_KEY', { public_key: public_key });
                this.$store.commit('SET_PASSCODE', { passcode: passcode });
            } 
            if(params.get('c')){
                debug.warn('customer params', customerN);
                let tempB = customerN === '99' ? true : false;
                this.$store.commit('SET_CUSTOMER', { state: tempB });
            }         

            this.$store.dispatch('GET_TOKEN').then(session => {
                if (session) {
                    debug.log('we have session');
                    // We collected all necesary data
                    this.$store.commit('SET_ISFETCHING', { state: false });
                    
                    //no parameters, show error
                    //else show detail
                    if(!this.isDocumentRequest){
                        this.$store.commit('SET_ISERROR', { state: true });
                        this.$store.commit('SET_ISFETCHING', { state: false });
                        if (this.apiErrormessage && !this.apiErrormessage.search('410')){
                            this.$store.commit('SET_API_ERRORMESSAGE', { message: 'Geen Document parameters meegegeven' });                        
                        }
                    } else {
                        //Do additional stuff
                    }
                }
            });
        }
    }
});
