import store from '@/store/store';
import { Module } from 'vuex';
import { IDocument } from '@/models/Document';
import { debug } from '@/services/Debugger';
import { AxiosResponse } from 'axios';

const document: Module<any, any> = {
    state: {
        isDocumentRequest: false,
        document_info: {},
        public_key: '',
        passcode: '',
        customer: false
    },
    getters: {
        isDocumentRequest: state => {
            return state.isDocumentRequest;
        },
        document_info: state => {
            return state.document_info;
        },
        public_key: state => {
            return state.public_key;
        },
        passcode: state => {
            return state.passcode;
        },
        customer: state => {
            return state.customer;
        },
        document_info_url: state => {
            return state.document_info.url;
        }
    },
    mutations: {
        SET_ISDOCUMENTREQUEST: (state, payload) => {
            state.isDocumentRequest = payload.state;
        },
        SET_DOCUMENT_INFO: (state, payload) => {
            state.document_info = payload.document_info;
        },
        SET_PUBLIC_KEY: (state, payload) => {
            state.public_key = payload.public_key;
        },
        SET_PASSCODE: (state, payload) => {
            state.passcode = payload.passcode;
        },
        SET_CUSTOMER: (state, payload) => {
            state.customer = payload.state;
        }
    },
    actions: {
        GET_DOCUMENT_INFO({ commit }) {
            debug.debug('act:GET_DOCUMENT_INFO');
            return this.getters.apiService.getDocument({ k: this.getters.public_key, p: this.getters.passcode }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    commit('SET_ISDOCUMENTREQUEST', { state: true });
                    let resultData: IDocument = result.data;
                    let replaceString = '';
                    let logoOwnCompany = null;
                    if (resultData.logo_own_company) {
                        switch (store.getters.APILocation) {
                            case 'local-local':
                                break;
                            case 'local-dev':
                            case 'dev':
                                replaceString = 'https://www.autoflex10.dev';
                                break;
                            case 'nl':
                                replaceString = 'https://www.autoflex10.nl';
                                break;
                            case 'eu':
                                replaceString = 'https://www.autoflex10.eu';
                                break;
                        }
                        if (replaceString) {
                            logoOwnCompany = resultData.logo_own_company.replace('http://localhost:8080', replaceString);
                        }
                    }
                    commit('SET_AFSETTINGS', {

                        settings: {
                            organization_id: resultData.organization_id,
                            name_own_company: resultData.name_own_company,
                            logo_own_company: resultData.logo_own_company
                        }
                    });
                    commit('SET_CONTACT_INFO', { contact_info: { shortname: resultData.shortname, v_display_name: resultData.v_display_name } });
                    commit('SET_CONTACT_ID', { contact_id: resultData.contact_id });
                    commit('SET_OWNER_ID', { owner_id: resultData.owner_id });
                    commit('SET_ORGANIZATION_LOGO', { logo: logoOwnCompany });
                    commit('SET_DOCUMENT_INFO', { document_info: resultData });
                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', { message: '(' + result.data.errorCode + ') ' + result.data.errorMessage });
                    return false;
                }
            });
        }
    }
};
export default document;
