















































import Vue from 'vue';
import store from '@/store/store';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debug } from '@/services/Debugger';
import { DateTime } from 'luxon';
import Vue2Filters from 'vue2-filters'

export default Vue.extend({
    name: 'af-documentinfo',
    computed: {
        ...mapGetters(['document_info', 'document_info_pdf', 'customer'])
    },
    data: function() {
        return{ 
            itemsFormatted: {factuurnummer: '', factuurdatum: '', factuurbedrag: '', factuurverval: '', betalingsconditie: ''},
            idealTotal: '',
            idealLink: '',
            payed: false
        }
    },
    mounted: function() {
        debug.log('mounted: DocumentInfo');
        this.createItems();      
    },
    methods: {
        createItems(){
            this.itemsFormatted.factuurnummer = this.document_info.invoice_number;
            this.itemsFormatted.factuurdatum = DateTime.fromISO(this.document_info.invoice_date).toISODate();
            this.itemsFormatted.factuurbedrag = this.document_info.order_total;
            this.itemsFormatted.factuurverval = DateTime.fromISO(this.document_info.payExpireDate).toISODate();
            this.itemsFormatted.betalingsconditie = this.document_info.payment_condition
            this.idealTotal = this.document_info.ideal ? this.document_info.ideal?.total : '';
            this.idealLink = this.document_info.ideal ? this.document_info.ideal?.url : '';
            this.payed = this.document_info.invoice_status;
        }       
    }

});
