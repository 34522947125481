import { debug } from '@/services/Debugger';
import { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { IAuthenticationComponent } from '@/models/AuthenticationComponent';
import { IAfSettings } from '@/models/AfSettings';
import { IAfModules } from '@/models/AfModules';
import { IContactClient } from '@/models/ContactClient';
import { IOwner } from '@/models/Owner';
import { IDocument } from '@/models/Document';
import qs from 'qs';
import store from '@/store/store';
import axios from 'axios';

export default class ApiService {
    components: {
        debug: any;
        store: any;
    };
    http: AxiosInstance;
    // set API url, for local, vip, dev or prod, should be default nl
    serverPath: string;

    constructor() {
        this.http = axios.create();
        // Request interceptor for API calls
        this.http.interceptors.request.use(
            async config => {
                const token = store.getters.appToken;
                const username = store.getters.username;
                const password = store.getters.password;
                if (token && !username && !password) {
                    config.headers.token = token;
                } else if (username && password) {
                    config.headers.token = token;
                    config.headers.authorization = 'Basic ' + btoa(username + ':' + password);
                }
                return config;
            },
            error => {
                Promise.reject(error);
            }
        );

        // Response interceptor for API calls
        this.http.interceptors.response.use(
            response => {
                return response;
            },
            async error => {
                const originalRequest: AxiosRequestConfig = error.config;
                if (error.response.status === 401) {
                    debug.warn('retry config');
                    debug.response('originalRequest: ', originalRequest);
                    const token = store.getters.appToken;
                    const username = store.getters.username;
                    const password = store.getters.password;
                    if (token && !username && !password) {
                        await this.getAuthenticateComponentToken();
                        originalRequest.headers.token = store.getters.Apptoken;
                    } else if (username && password) {
                        await this.getAuthenticateUser();
                        originalRequest.headers.token = store.getters.Apptoken;
                        originalRequest.headers.authorization = 'Basic ' + btoa(store.getters.username + ':' + store.getters.password);
                    }
                    return axios(originalRequest);
                }
                return error.response;
            }
        );
        this.setApiPath();
    }

    private setApiPath() {
        debug.info('setAPIurl');
        debug.log('APILocation', store.getters.APILocation);
        // environment prod/prod 2 -> nl - nl2
        // alleen uitzondering voor local en dev
        this.serverPath = 'https://www.autoflex10.' + store.getters.APILocation + '/autoflex/servoy-service/velocity/webservice_v2/';
        if (store.getters.APILocation === 'nl2') {
            this.serverPath = 'https://www2.autoflex10.nl/autoflex/servoy-service/velocity/webservice_v2/';
        } else if (store.getters.APILocation === 'local-local') {
            this.serverPath = 'http://localhost:8080/servoy-service/velocity/webservice_v2/';
        } else if (store.getters.APILocation === 'local-dev') {
            this.serverPath = 'https://www.autoflex10.dev/autoflex/servoy-service/velocity/webservice_v2/';
        }
        this.http.defaults.baseURL = this.serverPath;
        debug.log('serverPath', this.serverPath);
    }

    public async isServerAccessible(): Promise<boolean> {
        debug.info('ApiService / isServerAccessible');
        return this.getHealth()
            .then(result => {
                if (result.status === 200) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                return false;
            });
    }

    // get health
    public async getHealth(): Promise<AxiosResponse> {
        debug.info('ApiService / util/healthy');
        return this.http.get('util/healthy');
    }

    // get session token - based on component_token
    public async getAuthenticateComponentToken(): Promise<AxiosResponse<IAuthenticationComponent>> {
        const params = { component_token_id: store.getters.component_token };
        return this.http.get('/authenticate/component', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            }
        });
    }

    // get session token - based on username && password
    public async getAuthenticateUser(): Promise<AxiosResponse<IAuthenticationComponent>> {
        debug.info('ApiService / getAuthenticateUser');
        const params = {
            // api_key: '4C1A02B4-6BB0-4006-9672-7CE7EFED6FC2',
            api_key: '63D5161F-0984-4E8D-B9B3-F8F91D06AC12',
            // organization_name: 'Putten',
            username: store.getters.username,
            password: store.getters.password
        };
        return this.http.get('/authenticate', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            }
        });
    }

    // get settings
    public async getSettings(): Promise<AxiosResponse<IAfSettings>> {
        debug.info('ApiService / getAfSettings');
        const params = {
            fields: 'organization_id,name_own_company'
        };
        return this.http.get('/setting', {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            }
        });
    }

    // get modules
    public async getModules(): Promise<AxiosResponse<IAfModules>> {
        debug.info('ApiService / getAfModules');
        return this.http.get('/authenticate/modules');
    }

    // get document
    public async getDocument(payload: { k: string; p: string }): Promise<AxiosResponse<IDocument>> {
        debug.info('ApiService / getShortlink');
        return this.http.get('shorturl/info?k=' + payload.k + '&p=' + payload.p);
    }

    // get contact
    public async getContact(contact_id: string): Promise<AxiosResponse<IContactClient>> {
        debug.info('ApiService / getContact', contact_id);
        const params = {
            fields: 'shortname,gender,company_name,v_display_name'
        };
        return this.http.get('/contact/' + contact_id, {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            }
        });
    }

    // get owner
    public async getOwner(owner_id: string): Promise<AxiosResponse<IOwner>> {
        debug.info('ApiService / getOwner', owner_id);
        const params = {
            fields: 'customer_number,company_name,logo'
        };
        return this.http.get('/owner/' + owner_id, {
            params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            }
        });
    }
    // get organization logo
    public async getOrganizationLogo(organization_id: string): Promise<AxiosResponse<IOwner>> {
        debug.info('ApiService / getOrganizationLogo');
        //http://localhost:8080/servoy-service/velocity/webservice_v2/media?tableSchema=autoflex_cloud&tableName=settings&key=organization_id&uuid=74A01006-FB20-4B9C-A0B5-879E11332F35&mediaField=logo_own_company&mediaType=image
        return this.http.get(
            '/media?tableSchema=autoflex_cloud&tableName=settings&key=organization_id&uuid=' + organization_id + '&mediaField=logo_own_company&mediaType=image',
            { responseType: 'blob' }
        );
    }
}
