import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import { debug } from './services/Debugger';
import { BootstrapVue } from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import vueCustomElement from 'vue-custom-element';
import VueGtag from 'vue-gtag';
import Vue2Filters from 'vue2-filters';

// @ts-ignore
import vueNumeralFilterInstaller from 'vue-numeral-filter';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// store the app in a variable to prevent typescript from giving errors
const VueApp: any = App;

Vue.config.productionTip = false;

Vue.use(PortalVue);
//import Bootstrap plugins -> components
Vue.use(BootstrapVue);

Vue.use(vueNumeralFilterInstaller, { locale: 'nl-NL' });
Vue.use(vueCustomElement);

//Init analytics
Vue.use(VueGtag, {
    config: {
        id: 'G-WLSQMQEWPG',
        params: {
            send_page_view: false
        }
    }
});
var Vue2FiltersConfig = {
    capitalize: {
        onlyFirstLetter: false
    },
    number: {
        format: '0',
        thousandsSeparator: '.',
        decimalSeparator: ','
    },
    bytes: {
        decimalDigits: 2
    },
    percent: {
        decimalDigits: 2,
        multiplier: 100,
        decimalSeparator: '.'
    },
    currency: {
        symbol: '€',
        decimalDigits: 2,
        thousandsSeparator: '.',
        decimalSeparator: ',',
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: true,
        showPlusSign: false
    },
    pluralize: {
        includeNumber: false
    },
    ordinal: {
        includeNumber: false
    }
};

Vue.use(Vue2Filters, Vue2FiltersConfig);

// register it as a custom element
VueApp.store = store;
router();
Vue.customElement('oc-widget', VueApp);
