











import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'af-iserror',
    components: {},
    computed: {
        ...mapGetters(['loadingMessage', 'isFetching', 'debugMode', 'debugText'])
    }
});
