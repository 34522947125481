





import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'af-debugmode',
    components: {},
    computed: {
        ...mapGetters(['debugMode', 'debugText'])
    }
});
