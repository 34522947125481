













import Vue from 'vue';
import { debug } from '@/services/Debugger';

import AfOcHeader from '@/components/AfOcHeader.vue';

export default Vue.extend({
    name: 'portal',
    components: {
        AfOcHeader
    },
    data() {
        return {
            count: 0
        };
    },
    created: async function() {
        debug.log('created');
    },
    mounted: function() {
        debug.log('mounted');
    },
    computed: {},
    methods: {}
});
