import { Module } from 'vuex';
import { debug } from '@/services/Debugger';
import { AxiosResponse } from 'axios';
import { IAfSettings } from '@/models/AfSettings';
import { IAfModules } from '@/models/AfModules';

const globals: Module<any, any> = {
    state: {
        AfSettings: {
            setting_id: '',
            organization_id: '',
            name_own_company: ''
        },
        AfModules: {}
    },
    getters: {
        afSettings: state => {
            return state.AfSettings;
        },
        afModules: state => {
            return state.AfModules;
        }
    },
    mutations: {
        SET_AFSETTINGS: (state, payload) => {
            state.AfSettings = payload.settings;
        },
        SET_AFMODULES: (state, payload) => {
            state.AfModules = payload.modules;
        }
    },
    actions: {
        GET_AF_SETTINGS({ commit }) {
            debug.log('act:GET_AF_SETTINGS');
            return this.getters.apiService.getSettings().then((result: AxiosResponse) => {
                if (result.status === 200) {
                    let resultData: IAfSettings = result.data.data[0];
                    commit('SET_AFSETTINGS', { settings: resultData });
                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                    return false;
                }
            });
        },
        GET_AF_MODULES({ commit }) {
            debug.log('act:GET_AF_MODULES');
            return this.getters.apiService.getModules().then((result: AxiosResponse) => {
                if (result.status === 200) {
                    let resultData: IAfModules = result.data.modules.data;
                    commit('SET_AFMODULES', { modules: resultData });
                    return true;
                } else {
                    commit('SET_ISERROR', { state: true });
                    commit('SET_ISFETCHING', { state: false });
                    commit('SET_API_ERRORMESSAGE', '(' + result.data.errorCode + ') ' + result.data.errorMessage);
                    return false;
                }
            });
        }
    }
};
export default globals;
